<template>
  <view-item title="补货">
    <template #operation>
      <en-button type="primary" @click="operation.add.tenant.click">新增网点补货单</en-button>
      <en-button type="primary" @click="operation.add.warehose.click">新增仓库补货单</en-button>
      <en-button type="primary" @click="operation.add.click">新增调货</en-button>
      <button-ajax v-if="form.data.id" type="primary" :method="operation.save.click">保存</button-ajax>
      <button-ajax v-if="form.data.id" type="primary" :method="operation.purchase.click">生成采购单</button-ajax>
      <button-ajax v-if="form.data.id" type="primary" :method="operation.save.click">生成移库单</button-ajax>
      <en-dropdown v-if="form.data.id" @command="operation.option.command">
        <en-button>操作</en-button>
        <template #dropdown>
          <en-dropdown-item command="detail">补货单信息</en-dropdown-item>
          <en-dropdown-item command="delete">删除</en-dropdown-item>
        </template>
      </en-dropdown>
    </template>

    <template #manifest>
      <table-manifest
        :ajax="{ action: 'GET /enospray/replenishment/query', params: (params) => (params.payload = { rsexp: 'id,serialNo,warehouseName' }) }"
        :props="{ start: 'preparedStartDate', end: 'preparedEndDate' }"
        @row-click="manifest.row.click"
        @expand-click="dialog.visible = true"
      >
        <en-table-column label="单号" prop="serialNo"></en-table-column>
        <en-table-column label="仓库" prop="warehouseName"></en-table-column>

        <template #form="{ data }">
          <en-form-item label="仓库">
            <en-input v-model="data.warehouseName"></en-input>
          </en-form-item>
          <en-form-item label="制单人">
            <en-input v-model="data.preparedByName"></en-input>
          </en-form-item>
          <en-form-item label="审核日期">
            <en-date-picker v-model:start="data.auditStartDate" v-model:end="data.auditEndDate" type="daterange"></en-date-picker>
          </en-form-item>
        </template>
      </table-manifest>
    </template>

    <view-item-content :disabled="!form.data.id">
      <en-card v-loading="form.loading" body-class="flex flex-col gap-6">
        <div class="flex gap-4">
          <span>{{ form.data.warehouse?.name }}</span>
          <span>{{ form.data.serialNo }}</span>
          <span>{{ form.data.preparedBy }}/{{ formatDate(form.data.preparedDate) }}</span>
          <span>{{ form.summary.count }}(预计)/{{ form.summary.finalCount }}(实际)</span>
          <span>{{ form.data.lastReplenishDateDifference }}天未补货</span>
        </div>
      </en-card>

      <en-card v-loading="form.loading" class="flex-1 overflow-auto" body-class="h-full">
        <flex-box>
          <template #default="{ height }">
            <en-tabs v-model="tabs.active">
              <en-tab-pane
                label="配件"
                name="goods"
                :badge="form.data.replenishmentGoods?.length"
                :style="{ height: height - 55 + 'px', overflow: 'auto' }"
                class="gap-6"
              >
                <div class="flex items-center justify-around">
                  <div class="flex items-center flex-col gap-2">
                    <span>配件编号/名称</span>
                    <select-maintain
                      v-model="form.replenishmentGoods.operation.add.data.goods"
                      :ajax="{ action: 'GET /enospray/common/goods', params: (params, name) => (params.payload = { name, sprayMaterial: true }) }"
                      :props="{ label: 'storageGoodsSpecification.goods.serialNo', value: '' }"
                      value-key="id"
                      :disabled="form.disabled"
                      class="w-full"
                      @change="form.replenishmentGoods.operation.add.change"
                    ></select-maintain>
                  </div>

                  <div class="flex items-center flex-col gap-2">
                    <span>补货数量</span>
                    <en-input-number
                      v-model="form.replenishmentGoods.operation.add.data.count"
                      :disabled="form.disabled"
                      :precision="2"
                      class="w-full"
                      @keyup.enter.native="form.replenishmentGoods.operation.count.enter"
                    ></en-input-number>
                  </div>

                  <div class="flex items-center flex-col gap-2">
                    <span>配件名称</span>
                    <span>{{ form.replenishmentGoods.operation.add.data.goods?.storageGoodsSpecification?.goods.name || '--' }}</span>
                  </div>

                  <div class="flex items-center flex-col gap-2">
                    <span>规格</span>
                    <span>{{ form.replenishmentGoods.operation.add.data.goods?.storageGoodsSpecification?.name || '--' }}</span>
                  </div>
                </div>

                <flex-box>
                  <template #default="{ height }">
                    <en-table :data="form.data.replenishmentGoods" :height="height" @selection-change="form.replenishmentGoods.selection.change">
                      <en-table-column type="selection"></en-table-column>
                      <en-table-column label="操作" width="80">
                        <template #default="{ $index }">
                          <button-delete
                            :disabled="form.data.preparedBy !== store.user.name"
                            :method="form.replenishmentGoods.operation.delete.click"
                            :params="$index"
                          >
                            删除
                          </button-delete>
                        </template>
                      </en-table-column>
                      <en-table-column label="编码" prop="goods.storageGoodsSpecification.goods.serialNo" width="100"></en-table-column>
                      <en-table-column label="名称" prop="goods.storageGoodsSpecification.goods.name"></en-table-column>
                      <en-table-column label="总库存">
                        <template #default="{ row }: { row: EnosprayReplenishmentDefinitions['ReplenishmentGoodsDto'] }">
                          {{ formatNumber(row.inventory) }}{{ row.goods?.storageGoodsSpecification.name }}
                        </template>
                      </en-table-column>
                      <en-table-column label="采购预进">
                        <template #default="{ row }: { row: EnosprayReplenishmentDefinitions['ReplenishmentGoodsDto'] }">
                          {{ formatNumber(calculator.div(row.awaitingStockInCount ?? 0, row.goods?.storageGoodsSpecification.weight)) }}
                          {{ row.goods?.storageGoodsSpecification.name }}
                        </template>
                      </en-table-column>
                      <en-table-column label="调入在途">
                        <template #default="{ row }: { row: EnosprayReplenishmentDefinitions['ReplenishmentGoodsDto'] }">
                          {{ formatNumber(calculator.div(row.awaitingTransferInCount ?? 0, row.goods?.storageGoodsSpecification.weight)) }}
                          {{ row.goods?.storageGoodsSpecification.name }}
                        </template>
                      </en-table-column>
                      <en-table-column label="预计用量">
                        <template #default="{ row }: { row: EnosprayReplenishmentDefinitions['ReplenishmentGoodsDto'] }">
                          {{ formatNumber(row.usageCount) }}{{ row.goods?.storageGoodsSpecification.name }}
                        </template>
                      </en-table-column>
                      <en-table-column label="预计补货量" width="150">
                        <template #default="{ row }: { row: EnosprayReplenishmentDefinitions['ReplenishmentGoodsDto'] }">
                          <en-input-number v-model="row.count" :disabled="form.data.preparedBy !== store.user.name" class="w-full"></en-input-number>
                        </template>
                      </en-table-column>
                      <en-table-column label="实际补货数量" width="150">
                        <template #default="{ row }: { row: EnosprayReplenishmentDefinitions['ReplenishmentGoodsDto'] }">
                          <en-input-number
                            v-model="row.finalCount"
                            :disabled="form.data.preparedBy !== store.user.name"
                            class="w-full"
                          ></en-input-number>
                        </template>
                      </en-table-column>
                      <en-table-column label="已采购数量" prop="purchaseCount"></en-table-column>
                      <en-table-column label="已移库数量" prop="transferCount"></en-table-column>
                      <en-table-column label="喷涂供应商" prop="goods.spraySupplier.name"></en-table-column>
                      <en-table-column label="备注">
                        <template #default="{ row }: { row: EnosprayReplenishmentDefinitions['ReplenishmentGoodsDto'] }">
                          <en-input v-model="row.comment"></en-input>
                        </template>
                      </en-table-column>
                    </en-table>
                  </template>
                </flex-box>
              </en-tab-pane>
            </en-tabs>
          </template>
        </flex-box>
      </en-card>
    </view-item-content>
  </view-item>

  <purchase-replenishment-dialog v-model="dialog.visible"></purchase-replenishment-dialog>

  <purchase-replenishment-detail v-model="detail.visible" :type="detail.type"></purchase-replenishment-detail>
</template>

<script lang="ts">
import { EnMessage } from '@enocloud/components'
import { calculator } from '@enocloud/utils'

import PurchaseReplenishmentDetail from '@spray/components/purchase-replenishment-detail.vue'
import PurchaseReplenishmentDialog from '@spray/components/purchase-replenishment-dialog.vue'

export default factory({
  components: {
    PurchaseReplenishmentDetail,
    PurchaseReplenishmentDialog
  },

  config: {
    children: {
      operation: {
        add: {
          tenant: {
            click() {
              this.form.init()
              this.detail.type = 'tenant'
              this.detail.visible = true
            }
          },
          warehose: {
            click() {
              this.form.init()
              this.detail.type = 'warehose'
              this.detail.visible = true
            }
          }
        },
        purchase: {
          click() {
            if (!this.form.replenishmentGoods.selection.data.length) return EnMessage.warning('请选择补货商品')
            this.router.push('/spray/purchase', (vm) => {})
          }
        },
        save: {
          async click() {
            await this.form.submit()
            return this.form.get()
          }
        },
        option: {
          command(option: string) {}
        }
      },
      manifest: {
        row: {
          click(row: EnosprayReplenishmentDefinitions['ReplenishmentQueryDto']) {
            this.form.data.id = row.id
            this.form.get()
          }
        }
      },
      tabs: {
        active: 'goods'
      },
      form: {
        ajax: {
          get: {
            action: 'GET /enospray/replenishment/:replenishmentId',
            data: 'object',
            loading: true,
            init: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          submit: {
            action: 'PUT /enospray/replenishment',
            loading: true,
            params(params) {
              params.payload = this.form.data
            }
          }
        },
        computed: {
          summary() {
            return (this.form.data.replenishmentGoods ?? []).reduce(
              (summary, item) => {
                summary.count = calculator.add(summary.count, item.count)
                summary.finalCount = calculator.add(summary.finalCount, item.finalCount)
                return summary
              },
              {
                count: 0,
                finalCount: 0
              }
            )
          },
          disabled() {
            return false
          }
        },
        children: {
          replenishmentGoods: {
            selection: {
              data: [] as EnosprayReplenishmentDefinitions['ReplenishmentGoodsDto'][],
              change(rows: EnosprayReplenishmentDefinitions['ReplenishmentGoodsDto'][]) {
                this.form.replenishmentGoods.selection.data = rows
              }
            },
            operation: {
              add: {
                data: { goods: {}, count: 1 } as EnosprayReplenishmentDefinitions['ReplenishmentGoodsDto'],
                change(value: EnosprayReplenishmentDefinitions['SprayGoodsDto']) {
                  this.form.data.replenishmentGoods?.push({ goods: value, count: 1 })
                }
              },
              count: {
                enter() {
                  if (this.form.replenishmentGoods.operation.add.data.goods?.id) {
                    this.form.data.replenishmentGoods ??= []
                    this.form.data.replenishmentGoods.push({
                      goods: this.form.replenishmentGoods.operation.add.data.goods,
                      count: this.form.replenishmentGoods.operation.add.data.count
                    })

                    this.form.replenishmentGoods.operation.add.data = { goods: {} as EnosprayReplenishmentDefinitions['SprayGoodsDto'], count: 1 }
                  }
                }
              },
              delete: {
                click(index: number) {
                  this.form.data.replenishmentGoods?.splice(index, 1)
                }
              }
            }
          }
        }
      },
      detail: {
        visible: false,
        type: 'tenant'
      },
      dialog: {
        visible: false
      }
    }
  }
})
</script>
